import React from 'react'
import tw, {styled, css} from 'twin.macro'

const Modal = styled.div`
  ${tw`fixed inset-0 w-full h-full`}
  background-color: rgba(0,0,0,.7);
  transition: all .5s ease-in-out;
  opacity: 0;
  z-index: -100;
  pointer-events: none;
  outline: 0;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      transition: all .5s ease-in-out;
      z-index: 1000000;
      pointer-events: auto;
    `
  }
`

const ModalDialog = styled.div`
  ${tw`flex relative items-center justify-center absolute`}
  left: 50%;
  top: 50%;
  pointer-events: none;
  transition: transform .3s ease-out;
  transform: translate(0,0);

  @media only screen and (max-width: 449.98px){
    width: 350px;
  }

  @media only screen and (max-width: 360px){
    width: 300px;
  }

  @media only screen and (min-width: 450px){
    width: 400px;
    margin: .5rem;
  }

  @media only screen and (min-width: 576px) {
    max-width: 400px;
    margin: 1.75rem auto;
  }

  ${({ active }) =>
    active &&
    css`
      transform: translate(-50%,-50%);
    `
  }
`

const ModalContent = styled.div`
  ${tw`w-full bg-white flex items-center justify-center pt-10 px-4 sm:px-8 pb-5 flex-col relative`};
  pointer-events: auto;
  background-clip: padding-box;
  border: none;
  border-radius: 8px;
  outline: 0;
`

const ModalText = styled.p`
  ${tw`font-lato text-2xl leading-tight text-brand-dark text-center font-bold mb-8`}
`

const ModalButton = styled.button`
  ${tw`bg-brand-dark font-mulish flex justify-center items-center text-white font-semibold`}
  border-radius: 6px;
  padding: 15px 30px;
`

const ModalClose = styled.div`
${tw`absolute flex items-center justify-center text-brand-dark cursor-pointer`}
  font-size: 30px;
  right: 3px;
  top: 3px;
  width: 30px;
  height: 20px;
`

const NotificationModal = props => {
  return (
    <Modal active={props.active} onClick={props.closeModal}>
      <ModalDialog active={props.active} onClick={event => event.stopPropagation()}>
        <ModalContent>
          <ModalClose onClick={props.closeModal}>&times;</ModalClose>
          <ModalText>{props.successMessage}</ModalText>
          <ModalButton>This modal will close in {props.time} seconds</ModalButton>
        </ModalContent>
      </ModalDialog>
    </Modal>
  )
}

export default NotificationModal